<template>
  <div class="grid formgrid p-fluid">
    <div class="col-12">
      <Loader v-model="loading" />
        <Panel header="Configuracion de Impresoras">
          <div class="formgrid grid p-fluid">
            <div class="col-12">
                <FormToolbar :actions="['new','save','delete']" @new="newPrinter" @save="savePrinter" @delete="deletePrinter" />
            </div>
            <FormInputText wrapperClass="field col-12 xl:col-4" label="Nombre" v-model="printer.name" />
            <FormDropdown wrapperClass="field col-12 xl:col-4" label="Tipo" :options="[{id: 'serial', name: 'Serial'}, {id: 'network', name: 'Network'}, {id: 'usb', name: 'USB'}]" v-model="printer.type" :optionLabel="'name'" :optionValue="'id'" />
            <FormInputText wrapperClass="field col-12 xl:col-4" :label="printer.type == 'serial' ? 'Puerto Virtual' : printer.type == 'network' ? 'Direccion IP' : printer.type == 'usb' ? 'Puerto USB' : ''" v-model="printer.printer_config" />
            <FormInputSwitch wrapperClass="field col-12 xl:col-4" label="Cocina Predeterminada" v-model="printer.is_kitchen_default"></FormInputSwitch>
            <FormInputSwitch wrapperClass="field col-12 xl:col-4" label="Caja Predeterminada" v-model="printer.is_cashier_default"></FormInputSwitch>
           </div>
        </Panel>
    </div>
    <div style="padding-top: 2rem;" class="col-12">
      <BasicDatatable
          :rows="printers"
          :selectionMode="'single'"
          :headers="headers"
          @selected="selectPrinter"
          :footerVisible="false"
        />
    </div>
  </div>
</template>
<script>


import BasicDatatable from '../../components/general/BasicDatatable.vue';
import FormToolbar from '../../components/general/BasicFormToolbar.vue';
import FormDropdown from '../../components/general/FormDropdown.vue';
import FormInputText from '../../components/general/FormInputText.vue';
import Loader from "../../components/general/Loader.vue";

import { printer } from '../../models/printer';
import { fillObject, HeaderGrid } from '../../utilities/General';

import formMixin from '../../mixins/form';
import FormInputSwitch from '../../components/general/FormInputSwitch.vue';
export default {
  mixins: [formMixin],
  components: { FormInputSwitch, BasicDatatable, Loader, FormToolbar, FormInputText, FormDropdown},
  data() {
    return {
        printer: new printer(),
        printers: [],
        headers: [
          new HeaderGrid('Nombre', 'name'),
          new HeaderGrid('Tipo', 'type'),
          new HeaderGrid('Configuracion', 'printer_config'),
        ],
        loading: false
    };
  },
  methods: {
    selectPrinter(selected) {
      this.printer = fillObject(this.printer, selected);
    },
    newPrinter() {
      this.printer = new printer(this.session)
    },
    async savePrinter() {
      this.loading = true;
      try {
        let newPrinter;
        if (this.printer.id) {
          newPrinter = await this.printer.update();
        } else
          newPrinter = await this.printer.save();
        let index = this.printers.findIndex(p => p.id == newPrinter.id);
        
        if (index >= 0) 
          this.printers[index] = newPrinter;
        else 
          this.printers.push(newPrinter);
        this.newPrinter();
      } catch (error) {
        this.showError(error);
      } finally {
        this.loading = false;
      }
    },
    async refresh() {
      this.loading = true;
      try {
        this.printers = await this.printer.all();
      } catch (error) {
        this.showError(error);
      } finally {
        this.loading = false;
      }
    }
  },
  created() {
    this.newPrinter();
    this.refresh();
  }
};
</script>

<style>
</style>

