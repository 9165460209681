import axios from "axios";

export class printer
{
    constructor(session) {
        this.user = session ? session.user : null;
        this.id_company = session ? session.id_company : null;
        this.id_branch = session ? session.id_branch : null;
        this.token = session ? session.token : null;
        this.id = null;
        this.name = null;
        this.printer_config = null;
        this.type = "serial";
        this.created_by = null;
        this.created = null;
        this.modified_by = null;
        this.modified = null;
    }

    async all() {
        let response = await axios.get("tpv/printer", {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }

    async save() {
        let response = await axios.post("tpv/printer", this, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }

    async update() {
        let response = await axios.put("tpv/printer", this, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }
}