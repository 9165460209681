<template>
    <div :class="wrapperClass">
        <label v-if="label">{{label}}</label><br>
        <InputSwitch :disabled="disabled"  ref="control" v-model="localValue" 
        @input="$emit('update:modelValue', $event)"/>
        <small class="p-invalid" v-if="valid === false"> 
            {{validation}}
        </small>
    </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: [Boolean, null],
      default: false
    },
    modelValue: {
      type: [Boolean, null],
      required: true,
    },
    wrapperClass: {
        type: String,
        default: "field"
    },
    label: {
        type: [String, null],
        default: null
    },
    valid: {
        type: Boolean,
        //Todos son validos antes del submit
        default: true
    },
    validation: {
        type: String,
        default: "Favor de llenar el campo"
    },
  },
  data() {
    return {
      localValue: this.modelValue,
    };
  },
  watch: {
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    modelValue(newValue) {
      this.localValue = newValue;
    },
  },
};
</script>

<style>

</style>