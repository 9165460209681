<template>
<div>
    <OverlayPanel ref="toolbarOp">
            <Button
               v-for="button in visible_buttons" :key="button.value" 
               :label="button.name" :icon="button.icon" :class="button.class"
               @click="emitClose(button.emit)"
            />
            <slot name="custom-left"></slot>
    </OverlayPanel>
    <Toolbar v-if="visible" class="mb-4">
        <template #start>
            <Button
               v-for="button in visible_buttons" :key="button.value" 
               :label="button.name" :icon="button.icon" :class="button.class"
               @click="$emit(button.emit)"
            />
            <slot name="custom-left"></slot>
        </template>
        <template #end>
        <FileUpload
                mode="basic"
                v-if="actions.find(x => x == 'import')"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                :maxFileSize="1000000"
                label="Importar"
                :url="uploadURL"
                @upload="onUpload"
                @error="onUploadError"
                @before-send="onBeforeSend"
                @before-upload="onBeforeUpload"
                chooseLabel="Importar"
                class="p-button-help mr-2"
            />
            <Button
                v-if="actions.find(x => x == 'export')"
                label="Exportar"
                icon="pi pi-download"
                class="p-button-help"
                @click="$emit('export')"
            />
        </template>
    </Toolbar>
    <div v-else class="grid p-fluid" style="margin-bottom: 1rem;">
        <div class="col-12">
            <Button @click="openOp" class="p-button mr-2" style="text-align: center;">Ver Opciones</Button>
        </div>
    </div>
</div>
</template>

<script>
import { actions } from "../../utilities/MENU_ACTIONS";
const buttons = actions;
export default {
    data() {
        return {
            sidebar: false,
            buttons: buttons
        }
    },
    props: {
        actions: {
            type: Array,
            default: () => []
        },
        uploadURL: {
            type: String,
            default: ""
        },
    },
    computed: {
        visible() {
            return this.$windowWidth > 800;
        },
        visible_buttons() {
            return this.buttons.filter(button => {
                if (this.actions.find(x => x == button.value)) {
                    return button;
                }
            })
        }
    },
    methods: {
        onBeforeSend(request) {
            request.xhr.setRequestHeader('company', this.$storage.getStorageSync("company"));
            request.xhr.setRequestHeader('branch', this.$storage.getStorageSync("branch"));
            request.xhr.setRequestHeader('user', this.$storage.getStorageSync("usuario"));
            return request;
        },
        openOp(event) {
            this.$refs.toolbarOp.toggle(event);
        },
        emitClose(met) {
            this.$refs.toolbarOp.hide();
            this.$emit(met);
        },
        onBeforeUpload() {
            this.$emit('beforeUpload')
        },
        onUploadError(e) {
            this.$emit('uploadError', e.xhr.response)
        },
        onUpload() {
            this.$emit('uploaded')
        }
    }
}
</script>

<style scoped src="@/components/general/css/BasicFormToolbar.css">
</style>